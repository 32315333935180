/* body {
  margin: 0;
  padding: 0;
} */

.landing-comp {
  margin: 0 80px;
  padding: 0 80px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
}

.video-background {
  position: absolute;
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  height: 100%;
  object-fit: cover; /*Ensure video covers entire container */
  /* z-index: -1; Place video behind other content */
  overflow: hidden;
}

.landing-content {
  position: relative;
  z-index: 1; /* Ensure content is above video */
  /* Style your landing content here */
}

h1 {
  font-size: 7rem;
  font-family: "Orpheus Pro";
  font-weight: bold;
}

.landing-content h2 {
  font-family: "Orpheus Pro";
  font-weight: bold;
  font-size: 3rem;
  color: #004c00;
}

.my-link {
  /* margin-top: 50px; */
  text-decoration: none;
  font-size: 1rem;
  padding: 1.5rem 2.5rem;
  margin: 50px 10px 0px 10px;
  display: inline-block; /* Ensure the links are displayed as blocks */
  border-radius: 8px;
  border: 2px solid #008000;
  position: relative;
  color: white; /* Text color */
  transition: color 0.4s ease-in-out;
}

.my-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: #008000; /* Background color */
  transition: height 0.4s ease-in-out;
  z-index: -1; /* Place it behind the text */
  border-radius: 8px;
}

.my-link:hover::before {
  height: 100%; /* Expand the background height on hover */
}

@media (max-width: 576px) {
  .landing-comp {
    margin: 15px;
    padding: 0;
  }

  h1 {
    font-size: 2.6rem;
  }

  .landing-content h2 {
    font-size: 1.4rem;
  }

  .my-link {
    font-size: 0.8rem;
    padding: 0.5rem 0.5rem;
    margin: 15px 5px 0px 5px;
  }

  .video-background {
    position: absolute;
    top: 0;
    /* left: 0; */
    width: 100%;
    height: 100%;
    object-fit: cover; /*Ensure video covers entire container */
    /* z-index: -1; Place video behind other content */
    overflow: hidden;
  }
}

/* Example of media query for medium devices */
@media (min-width: 577px) and (max-width: 768px) {
  .landing-comp {
    margin: 20px;
    padding: 0;
  }

  h1 {
    font-size: 3.5rem;
  }

  .landing-content h2 {
    font-size: 2rem;
  }

  .my-link {
    font-size: 0.9rem;
    padding: 1rem 1rem;
    margin: 20px 5px 0px 5px;
  }
  .video-background {
    position: absolute;
    top: 0;
    /* left: 0; */
    min-width: 100%;
    height: 100%;
    object-fit: cover; /*Ensure video covers entire container */
    /* z-index: -1; Place video behind other content */
    overflow: hidden;
  }
  .btn-hov::before {
    background-color: transparent;
  }
}

/* Example of media query for large devices */
@media (min-width: 769px) and (max-width: 992px) {
  .landing-comp {
    margin: 0 80px;
    padding: 0;
  }

  h1 {
    font-size: 4rem;
  }

  .landing-content h2 {
    font-size: 2.1rem;
  }

  .my-link {
    font-size: 1rem;
    padding: 1rem 1rem;
    margin: 20px 10px 0px 10px;
  }
}

/* Example of media query for extra-large devices */
@media (min-width: 993px) and (max-width: 1200px) {
  .landing-comp {
    margin: 0 80px;
    padding: 0;
  }
  h1 {
    font-size: 5.5rem;
  }

  .landing-content h2 {
    font-size: 2.3rem;
  }
}

@media (hover: none) and (pointer: coarse) {
  .btn-hov::before {
    height: 100%; /* Set the effect without hover */
    background-color: transparent;
  }

  .btn-hov:hover::before {
    height: 100%; /* Ensure hover doesn't change the effect */
  }
}
