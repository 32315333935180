.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 80px;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 1.5rem;
  z-index: 1000;
  grid-template-areas: "logo" "links" ".";
}

.navbar-logo {
  grid-area: logo;
  font-size: 2.1rem;
  font-family: "Orpheus Pro";
  font-weight: bold;
  padding-top: 20px;
  align-self: top;
  text-decoration: none !important;
}

.navbar-links {
  grid-area: links;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  list-style: none;
  justify-content: center;
  text-align: left;
  padding: 0;
}

.navbar-link {
  text-decoration: none;
  font-size: 0.75rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: text-decoration 0.2s;
  position: relative;
}

.navbar-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #008000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navbar-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.logo-link {
  text-decoration: none;
  padding: 0px 10px;
  transition: text-decoration 0.2s;
  position: relative;
}

.logo-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 50%;
  left: 0;
  background-color: #008000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.logo-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbar-link.active {
  color: #008000;
}

.navbar-toggle {
  display: none;
}
.logo-absolute {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    z-index: 1000;
  }

  .logo-absolute {
    display: block;
    font-size: 2.1rem;
    font-family: "Orpheus Pro";
    font-weight: bold;
    padding-top: 20px;
    align-self: center;
    text-decoration: none !important;
    /* position: sticky;
    top: 0;
    z-index: 100; */
  }

  .logo-absolute.hidden {
    display: none;
  }

  .navbar-toggle {
    display: block;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    /* top: 1rem; */
    margin: 0;
    padding: 15px;
    /* margin: 15px; */
    z-index: 200;
    transition: transform 0.5s ease; /* Apply a transition for smoother animation */
  }
  .navbar-toggle.close {
    transform: rotate(90deg); /* Close icon styling */
  }
  .navbar-toggle span {
    display: block;
    width: 40px;
    height: 40px;
    position: relative;
    animation: spin 0.5s ease-in-out forwards;
    background-color: black;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .navbar-logo {
    position: fixed;
    top: -2.6%;
    left: 0;
    width: 100%;
    background-color: black;
    display: none;
    z-index: 100;
  }

  .logo-link {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-decoration: none;
  }
  .logo-link:after {
    background-color: transparent;
  }

  .navbar.show .navbar-logo {
    display: block;
    animation: slideIn 0.5s ease-in forwards;
    /* background-color: black; */
  }
  .navbar.hide .navbar-logo {
    display: block;
    animation: slideOut 1s ease-out forwards;
    transform: translateY(-100vh);
  }

  .navbar-links {
    display: none;
    /* top: -200%; */
    background-color: black;
    margin: 0;
    padding: 0 15px;
    z-index: 1;
    top: 0; /* Adjust the top position */
    transform: translateY(-100%);
    transition: transform 0.5s ease; /* Apply smooth transition */
  }
  .navbar-links li {
    margin: 15px 0;
  }

  .navbar-links.visible {
    transform: translateY(0); /* Show the links */
  }

  .navbar.show .navbar-links {
    display: block;
    animation: slideIn 0.5s ease-in forwards;
  }

  .navbar.hide .navbar-links {
    display: block;
    animation: slideOut 1s ease-out forwards;
    transform: translateY(-100vh);
  }

  @keyframes slideIn {
    from {
      transform: translateY(-100%);
      opacity: 1;
    }
    to {
      transform: translateY(25%);
      opacity: 1;
    }
  }

  @keyframes slideOut {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-100vh);
      opacity: 1;
    }
  }
}
