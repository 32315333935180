.services-container {
  margin: 0 80px;
  padding: 0 100px;
  border-radius: 8px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex; /* Add display flex */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  font-size: 2.6rem;
  font-family: "Orpheus Pro";
  font-weight: bold;
  padding-bottom: 1.5rem;
  margin: 0;
}
h3 {
  line-height: 2;
  font-family: "Poppins", sans-serif; /* Adjusted font-family */
  font-weight: 600;
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
  padding-top: 20px;
}

p {
  line-height: 1.6;
  font-family: "Poppins", sans-serif; /* Adjusted font-family */
  font-weight: 300;
  font-size: 1rem;
  margin: 0;
}

.arrow {
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-top: 50px;
  display: inline-block;
  position: relative;
  color: white;
  border: 2px solid #008000; /* Add border properties */
  transition: color 0.4s ease-in-out;
  z-index: 1;
  border-radius: 8px;
  cursor: pointer;
}

.arrow {
  color: #fff; /* Change arrow color as needed */
  font-size: 24px;
}

.arrow::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: #008000;
  transition: height 0.4s ease-in-out;
  z-index: -1;
  border-radius: 8px;
}

.arrow:hover::before {
  height: 100%;
}

.cta {
  margin-top: 50px;
  text-align: center;
}

.service-category {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin: 10px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.service-category:hover {
  transform: translateY(-5px);
}

.service-category h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  padding: 0;
  color: #004c00;
}

.service-category p {
  font-size: 1.1rem;
  line-height: 1.2;
}

@media (hover: none) and (pointer: coarse) {
  .arrow::before {
    height: 100%; /* Set the effect without hover */
    background-color: transparent;
  }
  .arrow:hover::before {
    height: 100%;
  }
}

@media (max-width: 768px) {
  .services-container {
    margin: 0px 50px;
    padding: 40px 0px;
    min-height: 100vh;
    height: fit-content;
  }
  .services-container p {
    font-size: 16px;
  }
  .services-container h3 {
    font-size: 20px;
    margin: 0;
  }
}

@media (min-width: 769px) and (max-width: 833px) {
  .services-container {
    margin: 40px 80px;
    padding: 0 50px;
  }
}

@media (min-width: 833px) and (max-width: 1080px) {
  .services-container {
    margin: 40px 80px;
    padding: 0 50px;
  }
}
