.skills-container {
  color: white;
  font-family: Arial, sans-serif;
  margin: 0 80px;
  padding: 0 100px;
  height: 100vh;
  display: flex; /* Add display flex */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  font-size: 2.6rem;
  font-family: "Orpheus Pro";
  font-weight: bold;
  padding-bottom: 1.5rem;
  margin: 0;
}

.skills-list {
  width: 100vw; /* Occupy full width of the container */
  max-width: 100%;
}

.skills-category {
  /* flex: 1; */
  width: 100vw; /* Occupy full width of the container */
  max-width: 100%;
}

.icon-grid {
  /* box-shadow: 0 4px 8px rgba(255, 255, 255, 0.5); */
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-wrap: wrap; /* Allow icons to wrap */
  gap: 20px;
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: hidden;
  justify-content: center; /* Center the content horizontally */
  align-items: center;
  margin: 0 auto; /* Center the grid itself */
  padding: 10px; /* Add horizontal padding */
}

.icon-container {
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  padding: 10px;
  flex: 1;
  margin: 10px;
  padding: 10px;
  box-sizing: border-box; /* Include padding in the width calculation */
}

.icon-name {
  display: none;
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  /* border: 1.5px solid black; */
  color: #008000;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.icon-container:hover .icon-name {
  display: block;
}

.icon {
  width: 90px; /* Adjust the size of icons */
  height: 90px; /* Adjust the height of icons */
  display: block;
  margin: 0 auto; /* Center the SVG horizontally */
  align-self: center;
}

.icon:hover {
  transform: translateY(-5px) scale(1.1);
}

h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .skills-container {
    margin: 40px 40px;
    padding: 0 0px;
    min-height: 100vh;
    height: fit-content;
  }

  div.icon-container {
    padding: 0px;
  }

  .icon-grid {
    display: grid;
    width: 80vw;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* grid-template-rows: [row1-start] 25% [row1-end] 100px [third-line] auto [last-line]; */
  }
  .icon-name {
    font-size: 0.8rem;
  }
}

@media (min-width: 769px) and (max-width: 833px) {
  .skills-container {
    margin: 40px 80px;
    padding: 0 50px;
    min-height: 100vh;
    height: fit-content;
  }
  .icon-grid {
    gap: 5px;
  }
  .icon {
    width: 60px; /* Adjust the size of icons */
    height: 60px; /* Adjust the height of icons */
    display: block;
    margin: 0 auto; /* Center the SVG horizontally */
    align-self: center;
  }
  div.icon-container {
    padding: 0px;
  }
}

@media (min-width: 833px) and (max-width: 1080px) {
  .skills-container {
    margin: 0 80px;
    padding: 0 50px;
  }
  .icon-grid {
    gap: 10px;
  }
  .icon {
    width: 60px; /* Adjust the size of icons */
    height: 60px; /* Adjust the height of icons */
    display: block;
    margin: 0 auto; /* Center the SVG horizontally */
    align-self: center;
  }
  div.icon-container {
    padding: 5px;
  }
}

@media (min-width: 1080px) and (max-width: 1340px) {
  .skills-container {
    margin: 0 80px;
    padding: 0 80px;
  }

  .icon-grid {
    gap: 10px;
  }
  .icon {
    width: 80px; /* Adjust the size of icons */
    height: 80px; /* Adjust the height of icons */
    display: block;
    margin: 0 auto; /* Center the SVG horizontally */
    align-self: center;
  }
  div.icon-container {
    padding: 5px;
  }
}
