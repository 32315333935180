.projects-container {
  margin: 0 80px;
  padding: 0 100px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex; /* Add display flex */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  color: black;
  font-size: 1rem;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
  width: 400px;
  border-radius: 8px;

  height: 300px;
}

.project-card img {
  max-height: 250px;
  width: 400px;
  /* border:; */
}

.project-card h3 {
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  color: black;
  font-weight: 600;
}

.hover-content {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 40px); /* Subtract padding to maintain size */
  height: calc(100% - 40px); /* Subtract padding to maintain size */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 8px; /* Add border-radius for hover content */
}

.project-card:hover .hover-content {
  opacity: 1;
}

.hover-content p {
  line-height: 1.6;
  font-family: "Poppins", sans-serif; /* Adjusted font-family */
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 10px;
  color: black;
}

.project-links a {
  display: block;
  text-decoration: none;
  color: black;
}

.project-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Adjust the space between icons */
  border: none;
}

.project-links a img {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease-in-out;
  border: none;
}

.project-links a:hover img {
  transform: scale(1.4); /* Adjust the scale factor as needed */
}

#tech {
  color: #004c00;
  font-size: 0.8rem;
}

h2 {
  font-size: 2.6rem;
  font-family: "Orpheus Pro";
  font-weight: bold;
  padding-bottom: 1.5rem;
  margin: 0;
}

/* Example of media query for small devices */
@media (max-width: 890px) {
  .projects-container {
    margin: 0 20px;
    padding: 40px 20px;
  }

  .projects-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .project-card {
    width: calc(100% - 40px); /* Adjust the width */
    margin-bottom: 20px; /* Increase margin for spacing */
  }
}
