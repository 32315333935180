.about-container {
  display: flex;
  align-items: center;
  margin: 0 80px;
  padding: 0 100px;
  justify-content: center;
  text-align: left;
  height: 100vh;
}

.about-container h2 {
  font-size: 2.6rem;
  font-family: "Orpheus Pro";
  font-weight: bold;
  padding-bottom: 1.5rem;
  margin: 0;
}

.about-container p {
  line-height: 1.6;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 1rem;
}

.image {
  width: 30vw;
}

.text {
  /* width: 50%;  */
}

/* .image img {
  width: 100%;
  height: auto;
  display: block;
} */

.hello {
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-top: 50px;
  display: inline-block;
  position: relative;
  color: white;
  border: 2px solid #008000;
  border-radius: 8px;
  transition: color 0.4s ease-in-out;
  z-index: 1;
}

.hello::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: #008000;
  transition: height 0.4s ease-in-out;
  z-index: -1;
  border-radius: 8px;
}

.hello:hover::before {
  height: 100%;
}

.hello:hover {
  color: white;
}

.github-content {
  display: flex;
  align-items: center;
}

.github-icon {
  width: 1em;
  height: 1em;
  margin-right: 0.3em;
}

@media (max-width: 768px) {
  .about-container {
    margin: 40px 40px;
    padding: 0 0px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: fit-content;
    /* height: auto; Reset height */
    /* text-align: center; */
  }

  .image {
    display: none;
  }

  .about-container h2 {
    padding-bottom: 0.5rem; /* Adjust padding */
    font-size: 1.4rem; /* Adjust heading font size for smaller screens */
  }

  .about-container p {
    font-size: 0.8rem; /* Adjust paragraph font size for smaller screens */
  }
  .hello {
    margin-top: 10px; /* Adjust margin */
    padding: 0.5rem 0.5rem;
  }
}

@media (min-width: 769px) and (max-width: 833px) {
  .about-container {
    margin: 0 80px;
    padding: 0 50px;
  }
  .image,
  .text {
    /* width: 80%; Adjust the width to occupy 80% of the container */
    /* max-width: 400px; Limit the max width to prevent excessive stretching */
    margin: 0 auto; /* Center the content */
  }
  .image {
    /* width: 40%; */
  }
  .about-container h2 {
    font-size: 1.8rem; /* Adjust heading font size for smaller screens */
  }

  .about-container p {
    font-size: 0.75rem; /* Adjust paragraph font size for smaller screens */
  }
  .about-container {
    height: 100vh; /* Ensure the container occupies 100vh */
  }
  .hello {
    margin-top: 20px; /* Adjust margin */
  }
}

/* Example of media query for medium devices */
@media (min-width: 833px) and (max-width: 992px) {
  .about-container {
    margin: 0 80px;
    padding: 0 50px;
  }
  .image,
  .text {
    /* width: 80%; Adjust the width to occupy 80% of the container */
    /* max-width: 400px; Limit the max width to prevent excessive stretching */
    margin: 0 auto; /* Center the content */
  }
  .image {
    /* width: 40%; */
  }
  .about-container h2 {
    font-size: 1.8rem; /* Adjust heading font size for smaller screens */
  }

  .about-container p {
    font-size: 0.8rem; /* Adjust paragraph font size for smaller screens */
  }
  .about-container {
    height: 100vh; /* Ensure the container occupies 100vh */
  }
}

/* Example of media query for large devices */
@media (min-width: 993px) and (max-width: 1200px) {
  .about-container {
    margin: 0 80px;
    padding: 0px 50px;
    height: 100vh;
  }
  .image,
  .text {
    /* width: 80%; Adjust the width to occupy 80% of the container */
    /* max-width: 400px; Limit the max width to prevent excessive stretching */
    margin: 0 auto; /* Center the content */
  }
  .text {
  }
  .image {
    /* width: 40%; */
  }
  .about-container h2 {
    font-size: 1.8rem; /* Adjust heading font size for smaller screens */
  }

  .about-container p {
    font-size: 0.9rem; /* Adjust paragraph font size for smaller screens */
  }
  .about-container {
    height: 100vh; /* Ensure the container occupies 100vh */
  }
}

@media (hover: none) and (pointer: coarse) {
  .hello::before {
    height: 100%; /* Set the effect without hover */
    background-color: transparent;
  }
  .hello:hover::before {
    height: 100%;
  }
}
