/* Footer.css */

.footer-container {
  text-align: center;
  padding: 20px;
  background-color: black;
  color: #fff;
  padding: 1rem;
}

.footer-container p {
  background-color: black;
  color: #fff;
  font-size: 0.75rem;
  font-family: "Poppins", sans-serif; /* Adjusted font-family */
  font-weight: 300;
}
