/* Contact.css */

.contact-container {
  margin: 0 80px;
  padding: 0 100px;
  border-radius: 8px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-container h2 {
  font-size: 2.6rem;
  font-family: "Orpheus Pro";
  font-weight: bold;
  padding-bottom: 1.5rem;
  margin: 0;
}

.contact-container h3 {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  padding-bottom: 1.4rem;
  margin: 0;
}

.contact-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* width: 100%; */
}

.contact-container .icon {
  width: 50px;
  height: 50px;
  /* background-color: white; */
  border-radius: 8px;
  border: 2px solid #008000;
  margin: 0;
  padding: 10px;
}

.contact-method {
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  text-decoration: none;
}

.contact-method p {
  color: #004c00;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none !important;
}

img.icon {
  margin: 0;
  padding: 0;
  margin-right: 0;
  align-self: center;
}

/* .contact-container p:hover {
  text-decoration: underline;
} */

.icon-container {
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  padding: 10px;
  flex: 1;
  margin: 10px;
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

p {
  text-decoration: none;
}

.icon-container a {
  text-decoration: none;
}
.icon-container a:hover {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}
.icon-name-it {
  color: white;
  margin: 0;
  text-decoration: none !important;
  cursor: default;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
}

/* Example of media query for small devices */
@media (max-width: 768px) {
  .contact-container {
    margin: 0 20px;
    padding: 0 20px;
  }

  .contact-icons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .contact-method {
    margin: 0.5rem;
  }

  .icon-container {
    flex: 1 1 45%; /* Adjust the width as needed */
    margin: 10px;
  }

  .icon-container p {
    font-size: 0.9rem; /* Adjust font size */
  }

  .icon {
    width: 40px;
    height: 40px;
    padding: 8px;
  }

  .icon-container .icon-name-it {
    font-size: 1rem; /* Adjust font size */
  }
}
